import React from "react";

import { Link } from "react-router-dom";

function Banner({ title }) {
  return (
    <>
      {/* Banner start */}
      <div className="banner banner-2" id="banner">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active item-bg">
              <img
                className="d-block w-100 h-100"
                src="assets/img/banner/img-3.jpg"
                alt="banner"
              />
              <div className="carousel-caption banner-slider-inner d-flex h-100 text-left">
                <div className="carousel-content container b1-inner-2">
                  <div className="text-center">
                    <div id="typed-strings">
                      <p>DÉNICHEZ LE BIEN DE VOS RÊVES AVEC NOTRE SERVICE <span style={{ background: "#af9483", color: "white" }}>CLÉ EN MAIN !</span>  </p>
                    </div>
                    <h3 className="typed-text">
                      &nbsp;
                      <span id="typed" />
                    </h3>
                    <div style={{ width: "75%", margin: "0px auto" }}>
                      <p className="text-p pl-4"  /* style={{ background: "#af948399" }} */ >
                        Nous gérons votre projet immobilier de A à Z avec une équipe d'experts dédiés, incluant courtiers , chasseurs immobiliers et architectes  . De la recherche du bien idéal à la remise des clés, nous vous offrons une expérience sans souci et des résultats impeccables.
                      </p>
                    </div>
                    <a href="#" className="btn btn-white" style={{ "text-transform": "none" }}>
                      <i className="fa fa-calendar pr-2" />
                      Prendre RDV avec un expert
                    </a>
                    <a href="#" className="btn btn-white-color" style={{ "text-transform": "none" }}>
                      <i className="fa fa-search pr-2" />
                      Votre Projet
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* banner end */}
    </>
  );
}

export default Banner;
