import React, { useEffect, useState } from "react";

function Footer() {
  const [visible, setVisible] = useState(false)

  const handleDialog = () => {
    setVisible(!visible)
  }

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, 10000)
  }, [])
  return (
    <>
      <>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-call" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center" id="exampleModalLabel">
                  Votre chasseur immobilier vous recontacte gratuitement
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form action="#" method="GET" encType="multipart/form-data">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-section fs-two">
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group name">
                              <label>Prénom</label>
                              <input
                                type="text"
                                name="prenom"
                                className="form-control"
                                placeholder="Prénom"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group email">
                              <label>Nom</label>
                              <input
                                type="text"
                                name="nom"
                                className="form-control"
                                placeholder="Nom"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group subject">
                              <label>E-mail</label>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="E-mail"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group number">
                              <label>Téléphone</label>
                              <input
                                type="text"
                                name="phone"
                                className="form-control"
                                placeholder="Téléphone"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <label>Budget</label>
                            <div className="form-group">

                              <select
                                className="selectpicker search-fields selectBox form-control"
                                name="Status"
                                style={{ width: "100%" }}

                              >
                                <option>Moins de 300 000€</option>
                                <option>De 300 000€ a 600 000€</option>
                                <option>De 600 000€ a 1 000 000€</option>
                                <option>Immobilier de prestige (plus de 2M€) </option>
                              </select>

                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <label>Votre disponibilité</label>
                            <div className="form-group">

                              <select
                                className="selectpicker search-fields selectBox form-control"
                                name="Status"
                                style={{ width: "100%" }}

                              >
                                <option>Le matin</option>
                                <option>Pause déjeuner</option>
                                <option>Après-midi</option>
                                <option>En soirée</option>
                                <option>Peu importe</option>
                              </select>

                            </div>
                          </div>


                          <div className="col-lg-12">
                            <div className="send-btn">
                              <button type="submit" className="btn btn-color btn-md">
                                Envoyer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
                <p className="text-justify" style={{ color: "#868e96d1" }}>
                  En soumettant ce formulaire, vous consentez à ce que l'agence BK Arch’Immo Arch'Immo traite vos données personnelles. Vos informations resteront strictement confidentielles et ne seront jamais partagées avec des tiers. Vous acceptez également de recevoir nos newsletters. Vous pouvez vous désabonner à tout moment en nous envoyant un courriel.
                </p>
              </div>

            </div>
          </div>
        </div>

      </>




      {/* Footer start */}
      {visible &&
        <div id="pinnedBottomRight" className="pinnedBottomRight">
          <div className="text-block header-bottom">
            <p>Discutons de votre projet
              <span class="close-btn-pero" onClick={handleDialog}>
                <i class="fa fa-close"></i>
              </span>
            </p>

          </div>
          <div className="content-container">
            <div className="d-flex align-items-center">
              <div className="image-block">
                <img
                  src="assets/img/speaker/speaker.jpeg"
                  alt="chasseur appart"
                />
              </div>
              <div className="text-block">
                <p>
                  Un chasseur immobilier vous répond <span className="highlight">gratuitement</span>
                </p>
              </div>
            </div>
            <div className="button-container">
              <a href="#" className="call-button" data-toggle="modal"
                data-target="#exampleModal">
                <i className="fa fa-phone pr-2" />
                JE VOUS APPELLE?
              </a>
            </div>
          </div>
        </div>
      }

      {!visible &&
        < div id="pinnedBottomRight" className="pinnedBottomRight" onClick={handleDialog}>
          <div class="close-button-perso-popup">
            <i class="fa fa-phone"></i>
          </div>
        </div >

      }


      <footer className="footer">
        <div className="container footer-inner">
          <div className="row">
            <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 wow fadeInLeft delay-04s">
              <div className="footer-item">
                <h4>Contactez-nous</h4>
                <ul className="contact-info">
                  <li>
                    <i className="fa fa-home" />
                    2 Villa Saint Michel 75018 Paris
                  </li>
                  <li>
                    <i className="fa fa-envelope" />
                    <a href="mailto:sales@hotelempire.com">contact@bk2v1m-arch-immo.fr</a>
                  </li>
                  <li>
                    <i className="fa fa-phone" />
                    <a href="tel:+55-417-634-7071">09 56 12 43 09</a>
                  </li>
                  <li>
                    <i className="fa fa-whatsapp" />
                    +337 55 95 28 06
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 wow fadeInLeft delay-04s">
              <div className="footer-item">
                <h4>Services</h4>
                <ul className="links">
                  <li>
                    <a href="#">Chasse Immobilière </a>
                  </li>
                  <li>
                    <a href="#">Investissement Locatif</a>
                  </li>
                  <li>
                    <a href="#">Location Résidentielle </a>
                  </li>
                  <li>
                    <a href="#">Location Saisonnière </a>
                  </li>
                  <li>
                    <a href="#">Architecture</a>
                  </li>
                  <li>
                    <a href="#">International</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow fadeInRight delay-04s">
              <div className="footer-item recent-posts">
                <h4>Support</h4>
                <div className="media mb-4">
                  <ul className="links">
                    <li>
                      <a href="#">Qui sommes-nous </a>
                    </li>
                    <li>
                      <a href="#">Conditions générales</a>
                    </li>
                    <li>
                      <a href="#">Faq</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>

                  </ul>
                </div>


              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInRight delay-04s">
              <div className="footer-item clearfix">
                <h4>Newsletter</h4>
                <div className="Subscribe-box">
                  <p>
                    Inscrivez-vous pour recevoir des informations et des mises à jour. Ne vous inquiétez pas, nous n'enverrons pas de spam !
                  </p>
                  <form action="#" method="GET">
                    <p>
                      <input
                        type="text"
                        className="form-contact"
                        name="email"
                        placeholder="Email"
                      />
                    </p>
                    <p>
                      <button
                        type="submit"
                        name="submitNewsletter"
                        className="btn btn-block btn-color"
                      >
                        Envoyer
                      </button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <p className="copy">
                  Copyright  © 2024 <a href="#">By BK Arch’Immo.</a> Tous Droits Réservés.
                </p>
              </div>
              <div className="col-lg-4 col-md-12">
                <ul className="social-list clearfix">
                  <li>
                    <a href="#" className="facebook-bg">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="twitter-bg">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="google-bg">
                      <i className="fa fa-google-plus" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="linkedin-bg">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer end */}
    </>
  );
}

export default Footer;
