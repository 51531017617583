import React from "react";
import { Link } from "react-router-dom";


function Header() {
  return (
    <>
      {/* Top header start */}
      <header className="top-header th-bg" id="top-header-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-7">
              <div className="list-inline">
                <a href="tel:1-7X0-555-8X22">
                  <i className="fa fa-home" />
                  2 Villa Saint Michel 75018 Paris
                </a>

              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-5">
              <ul className="top-social-media pull-right">
                <li>
                  <a href="#" className="sign-in">
                    <i className="fa fa-sign-in" /> Connexion / S'inscrire
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      {/* Top header end */}
      {/* main header start */}
      <header className="main-header sticky-header" id="main-header-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg navbar-light rounded">
                <a
                  className="navbar-brand logo navbar-brand d-flex w-50 mr-auto"
                  href="#"
                >
                  <img src="assets/img/logos/logo.png" alt="logo" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-controls="navbar"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="fa fa-bars" />
                </button>
                <div className="navbar-collapse collapse w-100" id="navbar">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown active">

                      <a
                        className="nav-link "
                        href="/"

                      >
                        Accueil
                      </a>

                    </li>
                    <li className="nav-item dropdown">

                      <a
                        className="nav-link "
                        href="/about"

                      >
                        Qui sommes-nous
                      </a>

                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdownMenuLink6"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Services
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink"
                      >

                        <li>
                          <a className="dropdown-item" href="/chasse-immobiliere">
                            Chasse Immobilière
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/investissement-locatif">
                            Investissement Locatif
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/location-residentielle">
                            Location Résidentielle
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Location Saisonnière
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Architecture
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            International
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown megamenu-li">
                      <a
                        className="nav-link "
                        href="#"

                      >
                        Actualité
                      </a>

                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link "
                        href="#"

                      >
                        Contact
                      </a>

                    </li>

                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* main header end */}
    </>
  );
}

export default Header;
