import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Banner2 from "../../../components/Banner2";
import "./style_lancer_projet.css";

function LancerProjet() {
  return (
    <>

      <div className=" design-lancer-projet-body">
        <div className="container-fluid ">
          <div style={{ height: "62px", background: "white", width: "100%" }}>

          </div>

          <div className="form-container">
            <div className="form">
              <h2>Créer votre compte</h2>
              <form>
                <label htmlFor="gender">Genre</label>
                <select id="gender" name="gender">
                  <option value="Mme.">Mme.</option>
                  <option value="M.">M.</option>
                </select>
                <label htmlFor="firstName">Prénom</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Prénom"
                />
                <label htmlFor="lastName">Nom</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Nom"
                />
                <label htmlFor="lastName">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
                <button type="submit" style={{ cursor: "pointer" }}>
                  Suivant
                </button>
              </form>

            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default LancerProjet;
