import React from "react";

import { Link } from "react-router-dom";

function Banner2({ title }) {
  return (
    <>
      {/* Sub banner start */}
      <div className="sub-banner overview-bgi">
        <div className="container">
          <div className="breadcrumb-area">
            <h1>{title}</h1>
            <ul className="breadcrumbs">
              <li>
                <a href="/">Accueil</a>
              </li>
              <li className="active">{title}</li>
            </ul>
          </div>
        </div>
      </div>
      {/* Sub banner end */}
    </>
  );
}

export default Banner2;
