import React, { useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";

import "./mortgage.css";

function MortgageSimulation() {

    const [selectedOption, setSelectedOption] = useState('');

    const handleSelection = (option) => {
        setSelectedOption(option);
    };


    return (
        <>
            <div className="situation-container">
                <div className="step-indicators">
                    <div className="step active">01</div>
                    <div className="step">02</div>
                    <div className="step">03</div>
                    <div className="step">04</div>
                </div>

                <h2>Situation</h2>
                <p>Vous investissez</p>

                <div className="options">
                    <div
                        className={`option ${selectedOption === 'seul' ? 'selected' : ''}`}
                        onClick={() => handleSelection('seul')}
                    >
                        <BsFillPersonFill /> Seul
                    </div>
                    <div
                        className={`option ${selectedOption === 'adeux' ? 'selected' : ''}`}
                        onClick={() => handleSelection('adeux')}
                    >
                        <i className="icon-couple"></i> À deux
                    </div>
                </div>
            </div>

            <div className="header relative w-full max-w-[24rem] rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <div className="">
                    <BsChevronLeft />
                </div>
                <div className="justify-center">
                    Simulateur
                </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div style={{ fontWeight: 'bold', fontSize: '20px', marginTop: '150px' }}>
                    Simulez votre capacité de financement en 5 minutes
                </div>
                <div>
                    Découvrez combien emprunter pour votre investissement dans le locatif !
                </div>
                <div style={{ fontWeight: 'bold', fontSize: '20px', marginTop: '80px' }}>
                    Situation
                </div>

                <div className="col-lg-8">
                    <div className="ml-4 mt-4 mb-2">Vous investissez</div>
                    <div className="d-flex justify-content-center">
                        <div
                            className={`option ${selectedOption === 'seul' ? 'selected' : ''}`}
                            onClick={() => handleSelection('seul')}
                        >
                            <BsFillPersonFill /> Seul
                        </div>
                        <div className="relative h-10 col-lg-5">
                            <input
                                disabled
                                type="email"
                                placeholder="Seul"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 pl-10 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50 focus:bg-[#800000]"
                            />
                            <BsFillPersonFill className="absolute top-1/2 left-3 transform -translate-y-1/2 text-blue-gray-700" />
                        </div>

                        <div className="relative h-10 col-lg-5">
                            <input disabled type="email" placeholder="À deux"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                    </div>
                    <div className="ml-4 mt-4 mb-2">Vous souhaitez investir dans</div>



                    <div className=" justify-content-center">

                        <div className="div-input relative h-10 col-lg-11">
                            <input disabled type="email" placeholder="Votre residence principale"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="Un investissement locatif"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="Je ne sais pas"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                    </div>
                    <div className="ml-4 mt-4 mb-2">Vous êtes</div>
                    <div className=" justify-content-center">

                        <div className="div-input relative h-10 col-lg-11">
                            <input disabled type="email" placeholder="Locataire de votre logement"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="Propriétaire de votre logement"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="Hégergé gratuitement"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="Dans un logement de fonction"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                    </div>
                    <div className="ml-4 mt-4 mb-2">Vous avez</div>
                    <div className=" justify-content-center">

                        <div className="div-input relative h-10 col-lg-11">
                            <input disabled type="email" placeholder="Age"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                    </div>
                    <div className="ml-4 mt-4 mb-2">Vous avez une ou des personnes à charge ?</div>
                    <div className="d-flex justify-content-center">

                        <div className="relative h-10 col-lg-5">
                            <input
                                type="email"
                                placeholder="Oui"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50"
                            />

                        </div>
                        <div className="relative h-10 col-lg-5">
                            <input disabled type="email" placeholder="Non"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                    </div>
                    <div className="ml-4 mt-4 mb-2">Vous habitez au</div>
                    <div className=" justify-content-center">

                        <div className="div-input relative h-10 col-lg-11">
                            <input disabled type="email" placeholder="Adresse"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                    </div>
                    <div className="ml-4 mt-4 mb-2">Vous êtes</div>
                    <div className=" justify-content-center">

                        <div className="div-input relative h-10 col-lg-11">
                            <input disabled type="email" placeholder="Salarié du secteur privé"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="Salarié du public"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="Travailleur non salarié"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="Autre"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                    </div>
                    <div className="ml-4 mt-4 mb-2">Plus précisement</div>
                    <div className=" justify-content-center">

                        <div className="div-input relative h-10 col-lg-11">
                            <input disabled type="email" placeholder="En CDI"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                        <div className="div-input relative mt-4 h-10 col-lg-11">
                            <input disabled type="email" placeholder="En CDD"
                                className="custom-rounded-input peer !border !border-gray-300 border-t-transparent bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-lg shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50" />
                        </div>
                    </div>
                </div>

            </div>



        </>
    );
}

export default MortgageSimulation;
